<template>
  <Layout>
    <div class="row" v-if="tribe">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-5 mt-1">
                <div class="m-4">
                  <div v-if="selected_media === 'cover_image'">
                    <img
                      :src="tribe.cover_image"
                      alt="Cover images"
                      class="cover-image-tribe"
                      srcset=""
                    />
                  </div>
                  <div v-if="selected_media === 'location'">
                    <GmapMap
                      :center="{
                        lat: parseFloat(tribe.latitude),
                        lng: parseFloat(tribe.longitude),
                      }"
                      :zoom="2"
                      style="height: 240px"
                    >
                      <GmapMarker
                        :position="{
                          lat: parseFloat(tribe.latitude),
                          lng: parseFloat(tribe.longitude),
                        }"
                        :clickable="false"
                        :draggable="false"
                      />
                    </GmapMap>
                    <div class="location-label mt-3">
                      <span>Latitude : {{ tribe.latitude }}</span
                      ><span>Longitude : {{ tribe.longitude }}</span>
                    </div>
                  </div>

                  <div v-if="selected_media === 'cover_video'">
                    <video-player
                      ref="videoPlayer"
                      :options="{
                        language: 'en',
                        playbackRates: [0.7, 1.0, 1.5, 2.0],
                        sources: [
                          {
                            type: 'video/mp4',
                            src: tribe.cover_video,
                          },
                        ],
                        poster: tribe.cover_image,
                      }"
                      :playsinline="true"
                    >
                    </video-player>
                  </div>
                  <div class="mt-4 text-center selected-media">
                    <label>
                      <input
                        type="radio"
                        value="cover_image"
                        v-model="selected_media"
                        name="selected_media"
                        autocomplete="off"
                      />
                      <span class="btn btn-outline-light m-1">Cover Image</span>
                    </label>
                    <label>
                      <input
                        style="margin-left: 10px"
                        type="radio"
                        value="cover_video"
                        v-model="selected_media"
                        name="selected_media"
                      />
                      <span class="btn btn-outline-light m-1">Cover Video</span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        value="location"
                        name="selected_media"
                        v-model="selected_media"
                      />
                      <span class="btn btn-outline-light m-1">Location</span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-xl-7">
                <div class="mt-4 mt-xl-3">
                  <h5 class="mt-1 mb-3">{{ tribe.name }}</h5>
                  <p>{{ tribe.title }}</p>

                  <p class="mt-3">
                    {{ tribe.primary_description }}
                  </p>
                  <hr class="my-4" />

                  <div class="row">
                    <div class="col-md-6">
                      <div>
                        <h5 class="font-size-14">Specification</h5>
                        <ul class="list-unstyled product-desc-list">
                          <li>
                            <i
                              class="mdi mdi-circle-medium mr-1 align-middle"
                            ></i>

                            Region is {{ findLabel("region", tribe.region) }}
                          </li>
                          <li>
                            <i
                              class="mdi mdi-circle-medium mr-1 align-middle"
                            ></i>
                            Number of days {{ tribe.no_of_days }}
                          </li>
                          <li>
                            <i
                              class="mdi mdi-circle-medium mr-1 align-middle"
                            ></i>
                            Challenge here is
                            {{ findLabel("challenge", tribe.challenge) }}
                          </li>
                          <li>
                            <i
                              class="mdi mdi-circle-medium mr-1 align-middle"
                            ></i>
                            Climate here is
                            {{ findLabel("climate", tribe.climate) }}
                          </li>
                          <li>
                            <i
                              class="mdi mdi-circle-medium mr-1 align-middle"
                            ></i>
                            This trip has been rated
                            <strong>{{ tribe.rating }} </strong>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="mx-3">
                      <h5 class="font-size-14">Transport</h5>
                      <div class="transport-wrapper">
                        <div
                          v-for="transport in tribe.transport"
                          :key="transport._id"
                          class="transport-container d-flex"
                        >
                          <img
                            :src="
                              require(`@/assets/images/travel/${transport.type}.png`)
                            "
                            :alt="transport.type"
                            class="mr-2"
                          />

                          <div>
                            <strong>{{ transport.title }}</strong>
                            <p class="pr-3">
                              {{ transport.text }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end row -->

            <div class="mt-4">
              <div class="product-desc">
                <b-tabs
                  class="nav-tabs-custom"
                  content-class="border border-top-0 p-4"
                >
                  <b-tab title="Details">
                    <table class="tribes-details-table">
                      <tr>
                        <td style="width: 138px">Life</td>
                        <td>
                          {{ tribe.life }}
                        </td>
                      </tr>
                      <tr>
                        <td>Home</td>
                        <td>
                          {{ tribe.home }}
                        </td>
                      </tr>
                      <tr>
                        <td>Social structure</td>
                        <td>
                          {{ tribe.social_structure }}
                        </td>
                      </tr>
                      <tr>
                        <td>Religion</td>
                        <td>
                          {{ tribe.religion }}
                        </td>
                      </tr>
                      <tr>
                        <td>Traditions</td>
                        <td>
                          {{ tribe.traditions }}
                        </td>
                      </tr>
                      <tr>
                        <td>Language</td>
                        <td>
                          {{ tribe.language }}
                        </td>
                      </tr>
                      <tr>
                        <td>Art</td>
                        <td>
                          {{ tribe.art }}
                        </td>
                      </tr>
                      <tr>
                        <td>Celebrations</td>
                        <td>
                          {{ tribe.celebrations }}
                        </td>
                      </tr>
                      <tr>
                        <td>Further knowledge</td>
                        <td>
                          {{ tribe.further_knowledge }}
                        </td>
                      </tr>
                    </table>
                  </b-tab>
                  <b-tab title="Images">
                    <div class="tribe-images">
                      <img
                        :src="img.image_url"
                        alt="Image"
                        v-for="img in tribe.images"
                        :key="img._id"
                      />
                    </div>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import { tribe } from "@/config/api/tribes";
import { findLabel } from "@/data/tribes";
export default {
  data() {
    return {
      tribe: undefined,
      findLabel: findLabel,
      selected_media: "cover_image",
    };
  },
  components: {
    Layout,
  },
  created() {
    this.getTribeDetails();
  },
  computed: {
    id() {
      return this.$route.query.id;
    },
  },
  methods: {
    getTribeDetails() {
      const api = tribe.getTribe;
      api.params = {
        tribeId: this.$route.query.id,
      };
      this.generateAPI(api)
        .then((res) => {
          this.tribe = res.data;
        })
        .catch((err) => {
          console.log(err.data);
        });
    },
  },
};
</script>

<style  scoped>
.transport-container img {
  width: 28px;
  height: 28px;
  margin-top: 6px;
}
.cover-image-tribe {
  margin-top: 18px;
  width: 100%;
  margin: auto;
  border-radius: 8px;
}
.tribes-details-table td {
  padding: 12px 14px;
  margin: 12px !important;
}
.tribes-details-table tr:nth-child(odd) {
  background-color: #fafafa;
}
.tribes-details-table td:first-child {
  color: #e83e8c !important;
  font-weight: 900;
}
.location-label span {
  margin-right: 8px;
  background-color: #e83e8c;
  color: #fff;
  border-radius: 6px;
  padding: 2px 6px;
}
.tribe-images {
  display: flex;
  flex-wrap: wrap;
}
.tribe-images img {
  max-width: 278px;
  margin: 4px;
  border-radius: 8px;
}
.selected-media [type="radio"]:checked + span {
  border: 2px solid #fa7da9;
  color: #000;
}

.selected-media [type="radio"]:checked,
[type="radio"]:not(:checked) {
  display: none;
}
</style>