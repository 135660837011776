export const tribe = {
  getAllTribes: {
    url: "admin/tribe/getAllForDashPaginated",
    method: "GET",
    params: null,
  },
  uploadMultimedia: {
    url: "admin/tribe/fileUpload",
    method: "POST",
    data: null,
  },

  postTribeService: {
    url: "admin/tribe/create",
    method: "POST",
    data: null,
  },
  updateTribeService: {
    url: "admin/tribe/update",
    method: "POST",
    data: null,
  },
  getTribe: {
    url: "admin/tribe/getOneTribe",
    method: "GET",
    params: null,
  },
  deleteTribe: {
    url: "admin/tribe/deleteTribe",
    method: "GET",
    params: null,
  },
};
