const TribesMetaData = {
  region: [
    {
      value: "australia",
      label: "Australia",
    },
    {
      value: "central_africa",
      label: "Central Africa",
    },
    {
      value: "europe",
      label: "Europe",
    },
    {
      value: "north_america",
      label: "North America",
    },
    {
      value: "northern_africa",
      label: "Northern Africa",
    },
    {
      value: "northern_asia",
      label: "Northern Asia",
    },
    {
      value: "south_america",
      label: "South America",
    },
    {
      value: "southern_africa",
      label: "Southern Africa",
    },
    {
      value: "southern_asia",
      label: "Southern Asia",
    },
    {
      value: "continental",
      label: "Continental",
    },
  ],
  climate: [
    {
      value: "arid",
      label: "Arid",
    },
    {
      value: "polar",
      label: "Polar",
    },
    {
      value: "tropical",
      label: "Tropical",
    },
    {
      value: "mild",
      label: "Mild",
    },
  ],
  challenge: [
    {
      value: "mild",
      label: "Casual",
    },
    {
      value: "moderate",
      label: "Medium",
    },
    {
      value: "extreme",
      label: "Intense",
    },
  ],
  transport: [
    {
      value: "bus",
      label: "Bus",
    },
    {
      value: "drive",
      label: "Drive",
    },
    {
      value: "ferry",
      label: "Ferry",
    },
    {
      value: "fly",
      label: "Fly",
    },
    {
      value: "walk",
      label: "Walk",
    },
  ],
};

/**
 * Find label corresponding to the type and value
 *
 * @param {String} type
 * @param {String} value
 */
function findLabel(type, value) {
  for (let i = 0; i < TribesMetaData[type].length; i++) {
    if (TribesMetaData[type][i]["value"] === value) {
      return TribesMetaData[type][i]["label"];
    }
  }
  return "Not Provided!";
}

export { TribesMetaData, findLabel };
